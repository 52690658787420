import React, { FunctionComponent } from 'react'
import * as S from './styles'
import Section from '@elements/Section'
import Typography from '@elements/Typography'
import { GridContainer, GridItem } from '@elements/Grid'
import { parseContent } from '@utils/content-parser'

type Props = {
  aboutMe: string
}

const IntroSection: FunctionComponent<Props> = ({ aboutMe }) => {
  return (
    <Section>
      <GridContainer>
        <GridItem>
          <Typography variant="h3">ABOUT ME</Typography>
          <GridContainer>
            <GridItem md={4}>
              <S.StyledVideo autoPlay loop muted playsInline>
                <source src="/night-animated-developer.mp4" type="video/mp4" />
              </S.StyledVideo>
            </GridItem>
            <GridItem md={8}>{parseContent(aboutMe)}</GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </Section>
  )
}

export default IntroSection
