import React from 'react'
import Typography from '@elements/Typography'

export const parseContent = (content: string): React.ReactNode => {
  const paragraphs = content.split('\n')

  return paragraphs.map((paragraph, index) => (
    <Typography key={`paragraph-${index}`}>{paragraph}</Typography>
  ))
}

const exporter = { parseContent }

export default exporter
