import { styled } from '@linaria/react'

export const H1 = styled.h1<TypographyProps>`
  font-size: 6rem;
  font-weight: 900;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`

export const H2 = styled.h2<TypographyProps>`
  font-size: 3.75rem;
  font-weight: 700;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`

export const H3 = styled.h3<TypographyProps>`
  font-size: 3rem;
  font-weight: 700;
  margin: ${({ margin }) => margin || '0 0 1rem 0'};
`

export const H4 = styled.h4<TypographyProps>`
  font-size: 2.125rem;
  font-weight: 500;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`

export const H5 = styled.h5<TypographyProps>`
  font-size: 1.5rem;
  font-weight: 500;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`

export const H6 = styled.h6<TypographyProps>`
  font-size: 1.25rem;
  font-weight: 500;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`

export const Subheading1 = styled.h6<TypographyProps>`
  font-size: 1rem;
  font-weight: 500;
  margin: ${({ margin }) => margin || '0'};
`

export const Subheading2 = styled.h6<TypographyProps>`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.35;
  margin: ${({ margin }) => margin || '0'};
`

export const Body1 = styled.p<TypographyProps>`
  font-size: 1rem;
  line-height: 1.35;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`

export const Body2 = styled.p<TypographyProps>`
  font-size: 0.875rem;
  line-height: 1.35;
  margin: ${({ margin }) => margin || '0 0 0.35rem 0'};
`
