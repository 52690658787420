import Typography from '@elements/Typography'
import React, { FunctionComponent } from 'react'
import * as S from './styles'
import Image from '@elements/Image'

interface Props {
  name: string
  logo: string
}

const SkillBar: FunctionComponent<Props> = ({ name, logo }) => {
  return (
    <S.SkillBarContainer>
      <Image src={logo} width="3rem" height="3rem" alt={name}></Image>
      <Typography margin="0" className={S.SkillTitle}>
        {name}
      </Typography>
    </S.SkillBarContainer>
  )
}

export default SkillBar
