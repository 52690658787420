import { styled } from '@linaria/react'
import { css } from '@linaria/core'

export const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  height: auto;
  width: 100%;
  z-index: 1;

  display: flex;
  justify-content: center;
`

export const NavigationBar = styled.div`
  --margin-gap: 1rem;

  max-width: calc(var(--max-width) + (var(--margin-gap) * 2));
  width: 100%;

  border-radius: 2rem;

  margin: 1rem var(--margin-gap);
  padding: 1rem;

  background-color: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(1rem);

  display: flex;
`

export const NavigationItems = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: auto 0 auto auto;
`

export const Icon = css`
  width: 2rem;
  height: 2rem;

  padding: 0.4rem;
  border-radius: 1rem;
  cursor: pointer;
  transition: 0.3s all;

  color: var(--foreground);

  &:hover,
  &:active {
    background-color: var(--foreground);
    color: rgba(0, 0, 0, 0.55);
  }
`
