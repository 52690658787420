import { styled } from '@linaria/react'
import { from } from '@utils/media'
import { populateWidthsWithSize } from '@utils/common'

export const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  width: 100%;
`

export const GridItem = styled.div`
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 100%;
  padding: 0.5rem;

  ${from('sm')} {
    ${populateWidthsWithSize('sm')}
  }

  ${from('md')} {
    ${populateWidthsWithSize('md')}
  }

  ${from('lg')} {
    ${populateWidthsWithSize('lg')}
  }

  ${from('xl')} {
    ${populateWidthsWithSize('xl')}
  }
`
