import React, { FunctionComponent } from 'react'
import { ImageProps } from 'next/dist/client/image'
import * as S from './styles'

const Image: FunctionComponent<
  {
    width?: string
    height?: string
  } & Omit<ImageProps, 'width' | 'height'>
> = ({ width, height, src, ...props }: any) => {
  const isSrcObject = typeof src === 'object'
  return (
    <S.ImageWrapper width={width} height={height}>
      <S.StyledNextImage
        {...props}
        src={src}
        placeholder={isSrcObject ? 'blur' : undefined}
        fill
      />
    </S.ImageWrapper>
  )
}

export default Image
