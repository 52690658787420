import React, { FunctionComponent, ReactNode } from 'react'
import * as S from './styles'

type Props = {
  children: ReactNode
  isFullViewPortHeight?: boolean
  className?: string
}

const Section: FunctionComponent<Props> = ({
  children,
  isFullViewPortHeight,
  className,
}) => {
  return (
    <S.Container
      isFullViewPortHeight={isFullViewPortHeight}
      className={className}
    >
      {children}
    </S.Container>
  )
}

export default Section
