import { styled } from '@linaria/react'

export const Container = styled.section<SectionProps>`
  width: 100%;
  height: ${({ isFullViewPortHeight }) =>
    isFullViewPortHeight ? '100vh' : 'auto'};
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
`
