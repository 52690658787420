import { css } from '@linaria/core'
import { styled } from '@linaria/react'
import { from } from '@utils/media'

export const StyledName = css`
  text-shadow: 2px 2px #000, -2px 2px #000, 2px -2px #000, -2px -2px #000,
    5px 5px 0px rgba(0, 0, 0, 0.4);
`

export const Subtitle = css`
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0 0.35rem 0;
`

export const Background = styled.div`
  background-image: url(/background.webp),
    linear-gradient(0deg, var(--background), #243b55 300%);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
`

export const CenterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
  gap: 2rem;
  flex-direction: column;

  ${from('md')} {
    flex-direction: row;
    height: 100%;
  }
`

export const AvatarContainer = styled.div`
  width: 15rem;
  height: 20rem;
  border-radius: 1rem;
  position: relative;
  box-shadow: 0 1px 3px hsl(0deg 0% 0% / 11%), 0 2px 6px hsl(0deg 0% 0% / 11%),
    0 3px 8px hsl(0deg 0% 0% / 14%);

  ${from('md')} {
    width: 400px;
    height: auto;
  }
`

export const ImageLayer = styled.div<ImageLayer>`
  border: 1px solid #ccc;
  transform: translate(0, 0);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: transform 1.5s, opacity 0.5s;
  border-radius: 1rem;

  &.loaded {
    transform: translate(
      ${({ level }) => `-${level * 0.5}rem,${level * 0.5}rem`}
    );
    opacity: ${({ level }) => 0.35 - level / 10};
  }
`

export const Heading = styled.div`
  text-align: center;

  ${from('md')} {
    text-align: left;
  }
`

export const StyledAvatar = css`
  border-radius: 1rem;
`
