import { Fragment } from 'react'
import type { NextPage, GetStaticProps } from 'next'
import LandingSection from '@layouts/LandingSection'
import Navigation from '@layouts/Navigation'
import IntroSection from '@layouts/IntroSection'
import SkillSection from '@layouts/SkillSection'
import { getProfile } from './api/profile'

type Props = {
  profile: {
    aboutMe: string
    skillSets: SkillSet[]
  }
}

const Home: NextPage<Props> = ({ profile }) => {
  const { aboutMe, skillSets } = profile
  return (
    <Fragment>
      <main>
        <Navigation></Navigation>
        <LandingSection></LandingSection>
        <IntroSection aboutMe={aboutMe}></IntroSection>
        <SkillSection skillSets={skillSets}></SkillSection>
      </main>
      <footer></footer>
    </Fragment>
  )
}

export const getStaticProps: GetStaticProps = () => {
  const data = getProfile()

  return {
    props: {
      profile: data,
    },
  }
}

export default Home
