import React, { FunctionComponent, ElementType } from 'react'
import * as S from './styles'

const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.toLowerCase().slice(1)
}

const Typography: FunctionComponent<TypographyProps> = ({
  variant = 'body1',
  margin,
  className,
  children,
}) => {
  const Styled: { [key: string]: ElementType } = S
  const Component: ElementType = Styled[capitalize(variant)]
  return (
    <Component margin={margin} className={className}>
      {children}
    </Component>
  )
}

export default Typography
