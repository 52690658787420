import { styled } from '@linaria/react'
import { css } from '@linaria/core'

export const SkillBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  box-shadow: 0 1px 3px hsl(0deg 0% 0% / 11%), 0 2px 6px hsl(0deg 0% 0% / 11%),
    0 3px 8px hsl(0deg 0% 0% / 14%);
`

export const SkillTitle = css`
  font-size: 1.25rem;
  font-weight: 500;
`
