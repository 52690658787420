import React, { FunctionComponent, ReactNode } from 'react'
import { BREAKPOINTS } from '@utils/constants'
import * as S from './styles'

type GridContainerProps = {
  children: ReactNode
}

type GridItemProps =
  | {
      children: ReactNode
    }
  | { [key in keyof typeof BREAKPOINTS as string]: number }

const generateSizeClassNames: Function = (
  breakpoints: (keyof typeof BREAKPOINTS)[]
): string => {
  const classNames: string[] = []
  for (const breakpointKey in breakpoints) {
    if (breakpoints[breakpointKey]) {
      classNames.push(`${breakpointKey}-${breakpoints[breakpointKey]}`)
    }
  }
  return classNames.join(' ')
}

export const GridContainer: FunctionComponent<GridContainerProps> = ({
  children,
  ...otherProps
}) => {
  return <S.GridContainer {...otherProps}>{children}</S.GridContainer>
}

export const GridItem: FunctionComponent<GridItemProps> = ({
  children,
  ...otherProps
}) => {
  const classNames = generateSizeClassNames(otherProps)
  return (
    <S.GridItem className={classNames} {...otherProps}>
      {children}
    </S.GridItem>
  )
}

const exporter = { GridContainer, GridItem }

export default exporter
