import React, { FunctionComponent } from 'react'
import * as S from './styles'
import Section from '@elements/Section'
import SkillBar from '@elements/SkillBar'
import { GridContainer, GridItem } from '@elements/Grid'
import Typography from '@elements/Typography'

type Props = {
  skillSets: SkillSet[]
}

const SkillSection: FunctionComponent<Props> = ({ skillSets }) => {
  return (
    <Section>
      <GridContainer>
        <GridItem>
          <Typography variant="h3" margin="3rem 0 0 0">
            SKILLS
          </Typography>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {skillSets.map((skillSet: SkillSet) => (
          <React.Fragment key={skillSet.name}>
            <GridItem md={12}>
              <S.SkillTitle>
                <Typography variant="h4" margin="0 1rem 0 0">
                  {skillSet.name}
                </Typography>
              </S.SkillTitle>
            </GridItem>
            {skillSet.skills.map(({ code, name, logoPath }: Skill) => (
              <GridItem md={4} sm={6} key={code}>
                <SkillBar key={code} name={name} logo={logoPath} />
              </GridItem>
            ))}
          </React.Fragment>
        ))}
      </GridContainer>
    </Section>
  )
}

export default SkillSection
