import React, { FunctionComponent } from 'react'
import Link from 'next/link'
import Image from '@elements/Image'
import * as S from './styles'

import GitHub from '@svgs/github.svg'
import StackOverFlow from '@svgs/stackoverflow.svg'

interface NavigationProps {}

const Navigation: FunctionComponent<NavigationProps> = () => {
  return (
    <S.Container>
      <S.NavigationBar>
        <Image
          src="/logo.svg"
          alt="Vu Thanh Nguyen's logo"
          width="2rem"
          height="2rem"
          sizes="(max-width: 768px) 240px, 400px"
          priority
        />
        <S.NavigationItems>
          <Link
            href="https://stackoverflow.com/users/9201587/nick-vu"
            aria-label="StackOverFlow"
          >
            <StackOverFlow className={S.Icon}></StackOverFlow>
          </Link>
          <Link href="https://github.com/vuthanhnguyen92" aria-label="GitHub">
            <GitHub className={S.Icon}></GitHub>
          </Link>
        </S.NavigationItems>
      </S.NavigationBar>
    </S.Container>
  )
}

export default Navigation
