import { styled } from '@linaria/react'
import NextImage from 'next/image'

export const ImageWrapper = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  width: ${(props) => props.width || 'auto'};
  height: ${(props) => props.height || 'auto'};
  min-width: ${(props) => props.width || 'auto'};
  min-height: ${(props) => props.height || 'auto'};
  max-width: ${(props) => props.width || 'auto'};
  max-height: ${(props) => props.height || 'auto'};
  > span {
    position: unset !important;
    border-radius: ${(props) => props.borderRadius || '0.25rem'};
  }
`

export const StyledNextImage = styled(NextImage)`
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
`
